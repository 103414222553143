import { createAction, props } from '@ngrx/store';
import { Comment, ISqlPagination } from 'lingo2-models';

export const loadComment = createAction('[Comment] Load comment', props<{ thread_id: string; comment_id: string }>());
export const loadCommentSuccess = createAction('[Comment] Load comment success', props<{ comment: Comment }>());

export const loadComments = createAction(
  '[Comment] Load comments',
  props<{ thread_id: string; pagination?: ISqlPagination }>(),
);

export const removeComment = createAction('[Comment] Remove comment', props<{ comment_id: string }>());

export const loadCommentsSuccess = createAction('[Comment] Load comments success', props<{ comments: Comment[] }>());

export const loadThread = createAction('[Comment] Load thread', props<{ thread_id: string }>());
export const loadThreadSuccess = createAction('[Comment] Load thread success', props<{ thread: Comment[] }>());
