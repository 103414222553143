import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { UserProfile, User } from 'lingo2-models';
import { AppState } from '..';
import * as UserAction from '../actions/users.actions';
import { IUserCollection } from '../models';

export interface State {
  currentUserId: string;
  currentProfile: UserProfile;
  users: IUserCollection;
}

const initialState: State = {
  currentUserId: null,
  currentProfile: null,
  users: {},
};

const userReducer = createReducer(
  initialState,
  on(UserAction.clearUser, () => ({ ...initialState })),
  on(UserAction.setCurrentUser, (state, { user }) => {
    if (!user) {
      return state;
    }
    const newState = {
      ...state,
      currentUserId: user.id,
    };
    if (!newState.users[user.id]) {
      newState.users = {
        ...newState.users,
        [user.id]: user,
      };
    }
    return newState;
  }),
  on(UserAction.setUser, (state, { user }) => ({
    ...state,
    users: {
      ...state.users,
      [user.id]: {
        ...state.users[user.id],
        ...user,
      },
    },
  })),
  on(UserAction.loadUsersSuccess, (state, { users }) => {
    const newUsers = users.reduce(
      (acc, user) => {
        acc[user.id] = user;
        return acc;
      },
      { ...state.users },
    );
    return {
      ...state,
      users: newUsers,
    };
  }),
  on(UserAction.setCurrentProfile, (state, { profile }) => ({
    ...state,
    currentProfile: profile,
  })),
  on(UserAction.updateContentStatsComplete, (state, { user_id, stats }) => {
    if (!state.users[user_id]) {
      return {
        ...state,
      };
    } else {
      const user = {
        ...state.users[user_id],
      };
      user.stats = {
        ...user.stats,
        publications_count: stats.published,
      };
      const newState = {
        ...state,
        users: {
          ...state.users,
          [user_id]: user,
        },
      };
      return newState;
    }
  }),
  on(UserAction.updateMeetingStatsComplete, (state, { user_id, stats }) => {
    if (!state.users[user_id]) {
      return {
        ...state,
      };
    } else {
      const user = {
        ...state.users[user_id],
      };
      user.stats = {
        ...user.stats,
        meetings_count: stats.published,
      };
      const newState = {
        ...state,
        users: {
          ...state.users,
          [user_id]: user,
        },
      };
      return newState;
    }
  }),
  on(UserAction.updateGameStatsComplete, (state, { user_id, stats }) => {
    if (!state.users[user_id]) {
      return {
        ...state,
      };
    } else {
      const user = {
        ...state.users[user_id],
      };
      user.stats = {
        ...user.stats,
        ...stats,
      };
      const newState = {
        ...state,
        users: {
          ...state.users,
          [user_id]: user,
        },
      };
      return newState;
    }
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

const thisFeatureKey = 'users';
export const userFeatureKey = thisFeatureKey;

/* Selectors */
export const getCurrentUser = (state: any) => {
  const thisState: State = state[thisFeatureKey];
  if (!thisState.currentUserId) {
    return null;
  }
  return new User(thisState.users[thisState.currentUserId]);
};
export const getUsers = (state: AppState): IUserCollection => state[thisFeatureKey].users;
export const getCurrentProfile = (state: AppState) => state[thisFeatureKey].currentProfile;

export const getUserById = createSelector(getUsers, (users, props: { user_id: string }): User => users[props.user_id]);
